<template>
	<div>
		<div class="handle-box" style="margin-bottom: 20px;">
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-plus"
				@click="handleSearch">添加</el-button>
		</div>
		<el-table :data="tableData" border class="table el-table" ref="multipleTable"
			header-cell-class-name="table-header" :header-cell-style="{ 'text-align': 'center' }"
			@selection-change="handleSelectionChange" :row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
			:row-class-name="tableRowClassName">
			<el-table-column prop="sort" label="排序" align="center"></el-table-column>
			<el-table-column prop="url" label="图片" align="center">
				<template #default="scope">
					<el-image v-if="scope.row.url" @click="bigImg(scope.row.url)"
						style="width: 50px; height: 50px; margin-bottom:-4px" :src="scope.row.url"
						:preview-src-list="srcList"></el-image>
					<span v-else></span>
				</template>
			</el-table-column>

			<el-table-column label="操作" width="190" align="center">
				<template #default="scope">
					<el-button size="small" type="primary" :class="[theme]"
						@click="getupdateLoginSlideshow(scope.row)">排序</el-button>
					<el-button size="small" type="danger" @click="getdeleteLoginSlideshow(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog v-model="addForm" width="450px" :show-close="false" :close-on-click-modal="false">
			<el-form ref="form" :model="form" label-width="120px" class="OEM-form">
				<el-form-item label="排序"><el-input v-model="form.sort"></el-input></el-form-item>
				<el-form-item label="图片" prop="file">
					<el-upload :disabled="eiteTrue" :action="file" :limit="1" list-type="picture-card"
						:file-list="fileList1" :auto-upload="false" ref="upload1"
						accept="image/png,image/jpg,image/jpeg" :on-change="getfile" :data="token1"
						:class="{ hide: hideUpload1 }" :on-preview="handlePictureCardPreview1"
						:on-remove="handleRemove1" :before-remove="beformRemove1">
						<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
					</el-upload>
					<el-dialog v-model="addVisible1"><img :src="form.base64" style="width: 100%;" /></el-dialog>
				</el-form-item>

				<el-form-item label="建议尺寸">7087px*3543px</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeAddForm">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="submitAddForm">确认</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog v-model="addForm2" width="550px" :show-close="false" :close-on-click-modal="false">
			<el-form ref="form" :model="form" label-width="80px" class="OEM-form">
				<el-form-item label="排序"><el-input v-model="form.sort"></el-input></el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeAddForm">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]"
						@click="submitupdateLoginSlideshow">确认</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		loginSlideshowList,
		addLoginSlideshow,
		deleteLoginSlideshow,
		updateLoginSlideshow
	} from '../api/oem';
	export default {
		name: 'OEMswiper',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				tableData: [],
				form: {
					file: '',
					sort: ''
				},
				addForm2: false,
				addForm: false,
				dialogImageUrl1: null,
				addVisible1: false,
				hideUpload1: false,
				fileList1: [],
				limitCount: 1,
				url: '',
				srcList: []
			};
		},

		created() {
			this.getloginSlideshowList();
		},
		methods: {
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getloginSlideshowList() {
				let data = {
					userId: localStorage.getItem('user')
				};
				loginSlideshowList(data).then(res => {
					this.tableData = res.data;
				});
			},
			// 删除照片
			handleRemove1() {
				console.log(this, '呜呜呜呜');
				this.form.base64 = '';
			},
			//查看大图
			handlePictureCardPreview1(file) {
				this.dialogImageUrl1 = file.url;
				this.addVisible1 = true;
			},
			// 上传照片后隐藏上传按钮
			beformRemove1(fileList) {
				this.hideUpload1 = fileList.length >= this.limitCount;
			},
			//第一张图片
			getfile(file, fileList) {
				this.hideUpload1 = fileList.length >= this.limitCount;
				this.getBase64(file.raw).then(res => {
					this.form.base64 = res;
				});
			},
			// 转图片转vase64
			getBase64(file) {
				return new Promise(function(resolve, reject) {
					let reader = new FileReader();
					let imgResult = '';
					reader.readAsDataURL(file);
					reader.onload = function() {
						imgResult = reader.result;
					};
					reader.onerror = function(error) {
						reject(error);
					};
					reader.onloadend = function() {
						resolve(imgResult);
					};
				});
			},

			getdeleteLoginSlideshow(row) {
				// 确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							url: row.url,
							userId: localStorage.getItem('user')
						};
						deleteLoginSlideshow(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getloginSlideshowList();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			handleSearch() {
				this.addForm = true;
				this.form.sort = '';
				this.form.base64 = '';
			},
			closeAddForm() {
				this.addForm = false;
				this.addForm2 = false;
			},
			submitAddForm() {
				let data = {
					base64: this.form.base64,
					sort: this.form.sort,
					userId: localStorage.getItem('user')
				};
				addLoginSlideshow(data).then(res => {
					if (res.code == 200) {
						this.addForm = false;
						this.addForm2 = false;
						this.limitCount = 1;
						this.hideUpload1 = false;
						this.fileList1 = [];
						this.$message.success('添加成功');
						this.getloginSlideshowList();
					} else {
						this.$message.error(res.message);
					}
				});
			},

			getupdateLoginSlideshow(row) {
				this.url = row.url;
				this.form.sort = '';
				this.addForm2 = true;
			},
			submitupdateLoginSlideshow() {
				let data = {
					url: this.url,
					sort: this.form.sort,
					userId: localStorage.getItem('user')
				};
				updateLoginSlideshow(data).then(res => {
					if (res.code == 200) {
						this.addForm2 = false;
						this.$message.success('排序成功');
						this.getloginSlideshowList();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			bigImg(url) {
				this.srcList.push(url);
			}
		}
	};
</script>
<style>
	.red {
		color: #f56c6c;
	}
</style>