<template>
	<div>
		<div class="container">
			<div class="handle-box">

				<el-input size="small" v-model="query.phone" placeholder="请输入手机号" style="width: 180px"
					class="handle-input mr10 mb10 m_t_10"></el-input>
				<el-date-picker size="small" type="date" placeholder="选择日期" v-model="query.startTime"
					style="width: 200px;margin-right: 10px;" @change="getTime"></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload2"
					style="margin:0 10px 20px 10px;" @click="handleDownload">导出</el-button>
			</div>
			<el-row>
				<el-col :span="24">
					<div id="ai-tab">
						<el-table :data="tableData" border class="table AI-tab-box" ref="multipleTable"
							header-cell-class-name="table-header" :cell-style="{ cursor: 'pointer', padding: '6px' }"
							:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
							:row-class-name="tableRowClassName" highlight-current-row @row-click="handleRowClick">
							<el-table-column prop="calledPhone" label="被叫号码" align="center"></el-table-column>
							<el-table-column prop="callingPhone" label="主叫号码" align="center"></el-table-column>
							<el-table-column prop="duration" label="通话时长(单位/s)" align="center"></el-table-column>
							<el-table-column prop="callStartTime" label="拨打时间" align="center"></el-table-column>
							<el-table-column prop="violationReason" label="违规原因" align="center"></el-table-column>
						</el-table>
					</div>
				</el-col>
			</el-row>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[100, 300, 500, 1000]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		cycjErrRecord,
		exportCJErrRecord
	} from '../api/index.js';
	export default {
		name: 'cjabnormalCall',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					pageIndex: 1,
					pageSize: 30,
					phone: '',
					startTime: new Date(new Date())
				},
				time: '',
				tableData: [],
				pageTotal: 0,
			};
		},

		created() {
			this.getTime()
		},

		methods: {
			getData() {
				let data = {
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
					phone: this.query.phone,
					time: this.time,
				}
				cycjErrRecord(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getTime() {
				if (this.query.startTime) {
					this.time = this.toDateTimeStr(this.query.startTime);
					this.getData();
				} else {
					this.time = this.query.startTime;
					this.getData();
				}
			},
			toDateTimeStr(nS) {
				let date = new Date(nS);
				let YY = date.getFullYear() + '-';
				let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
				let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return YY + MM + DD;
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},

			handleDownload() {
				let data = {
					time: this.time
				}
				exportCJErrRecord(data).then(res => {
					if (res.type == 'application/json') {
						return this.$message.error(res.message)
					}
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = '长江异常话单' + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
				});
			},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.mr10 {
		margin-right: 10px;
	}

	.red {
		color: #f56c6c;
	}

	.tag-box {
		padding: 6px 20px;
		display: flex;
		flex-direction: column;
	}

	.tag-box p {
		display: inline-block;
	}

	.tag-box p span {
		display: inline-block;
		background-color: #f56c6c;
		color: #fff;
		padding: 4px 6px;
		margin-bottom: 6px;
		border-radius: 4px;
	}
</style>